import {
    AnkaFetch,
    Base,
    BufferAttribute,
    BufferGeometry,
    Color,
    Geometry,
    PointsMaterial,
    Points,
    Sphere,
    Vector3
} from './ImportHelper'
import { PatchPooler } from './PatchPooler'


let materials = new PointsMaterial({ vertexColors: THREE.VertexColors, size: 2, sizeAttenuation: false })
let boxMaterial = new PointsMaterial()

class PointBox extends Base {
    constructor (panogl, scene, bound) {
        super()

        this._panogl = panogl
        this._scene = scene
        let { min, max } = bound
        let geom = new BufferGeometry()
        geom.addAttribute('position', new BufferAttribute(new Float32Array([]), 3))
        let bounder = new Points(geom, boxMaterial)
        scene.add(bounder)
        geom.boundingBox = bound
        let sphere = new Sphere()
        sphere.setFromPoints([min, max])
        geom.boundingSphere = sphere

        this._bounder = bounder
        this._seen = false
        this._onBeforeRender = this._onBeforeRender.bind(this)
        bounder.onBeforeRender = this._onBeforeRender
        this._onWorkerMessage = this._onWorkerMessage.bind(this)
    }

    _onBeforeRender () {
        if (!this._seen) {
            this._seen = true
            this.waitingForDownload = true
            this._bounder.visible = false
            this.throwEvent({ type: PointBox.SEEN, target: this })
        }
    }

    setPosition (position) {
        let points = this._points
        points.position.x = position.x
        points.position.y = position.y
        points.position.z = position.z
    }

    destroy () {
        this._abort = true
        let bounder = this._bounder
        if (bounder) {
            let parent = bounder.parent
            if (parent) {
                parent.remove(bounder)
            }
            bounder.geometry.dispose()
        }

        let points = this._points
        if (points) {
            let parent = points.parent
            if (parent) {
                parent.remove(points)
            }
            points.geometry.dispose()
        }

        this._scene = null
    }

    setData (data, standingData) {
        this._data = data
        this._standingData = standingData
    }

    getData () {
        return this._data
    }

    load (url) {
        if (this._abort) {
            return
        }

        let { id } = this._data
        let patches = PatchPooler.getByID(id)
        if (patches !== undefined) {
            this._processPoints(patches)
            return
        }

        AnkaFetch(url)
            .then(e => {
                if (this._abort) return
                let json = e.json()
                return json
            })
            .then(result => {
                if (this._abort) return

                if (result && result.success) {
                    var points = result.points
                    PatchPooler.addByID(points, id)
                    this._processPoints(points)
                } else {
                    console.log('Not succesfull')
                }
            })
    }

    addPointsToVertices (pcPoints) {
        if (this._abort) {
            return
        }

        let geom = new Geometry()

        pcPoints.forEach(pt => {
            let pos = new Vector3(pt.x, pt.y, pt.z)
            geom.vertices.push(pos)
            let dist = Math.sqrt(pos.x * pos.x + pos.z * pos.z)
            geom.colors.push(new Color(dist / 800, 1 - (dist / 2500), 0))
        })

        let scene = this._scene
        let points = new Points(geom, materials)
        scene.add(points)
        this._points = points
    }

    _onWorkerMessage (message) {
        let worker = this._currentWorker
        var data = message.data

        if (data.type === 'CARTESIAN_POINTS') {
            this.addPointsToVertices(data.points)
            AnkaPanAPI.WorkerManager.releaseWorker(worker)
            this.throwEvent({ type: PointBox.COMPLETE, target: this })
        } else {
            console.log(message.data)
        }

        worker.removeEventListener('message', this._onWorkerMessage)
    }

    _processPoints (points) {
        if (this._abort) {
            return
        }

        var workerBlobArray = window.getWorker()
        var worker = AnkaPanAPI.WorkerManager.getBlobWorker('PointBox', workerBlobArray)

        worker.addEventListener('message', this._onWorkerMessage)

        let standingData = this._standingData
        let currentPoint = {
            alt: standingData.altitude,
            lat: standingData.lat,
            lon: standingData.lon
        }

        worker.postMessage({
            type: 'SET_DATA',
            points: points,
            center: currentPoint,
            cameraHeightInPX: this._panogl.groundMaster.cameraHeightInPixel,
            cameraHeightInMeter: this._panogl.groundMaster.cameraHeight
        })

        this._currentWorker = worker
        worker.postMessage({ type: 'START' })
    }
}

PointBox.SEEN = 'onSeen'
PointBox.COMPLETE = 'onComplete'

export { PointBox }
