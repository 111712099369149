import { PCTool } from './PCTool'
import { GeomDataHolder, AnkaFetch, AnkaPromise, SLine } from '../ImportHelper'
import { addPointCreator, endEventCreator } from './EventCreator'

class CloudPointFromGroundTool extends PCTool {
    constructor(panogl) {
        super(panogl, SLine, GeomDataHolder.LINE)
        this.type = 'Line'
    }

    endDraw() {
        let dh = this.dh
        let pointNumber = dh.getPointLength()
        let isSuccessful = false
        if (pointNumber < 2) {
            dh.setStatus(GeomDataHolder.STATUS.BAD_COMPLETED)
        } else {
            isSuccessful = true
            dh.setStatus(GeomDataHolder.STATUS.COMPLETED)
        }
        this.throwEvent(endEventCreator(this.type, isSuccessful, this.dh))
    }

    onPointsReceived(points) {
        if (points.length === 2) {
            let [topPoint, bottomPoint] = points
            this.addPoints([topPoint, bottomPoint])
            this.endDraw()
        }
    }

    queryLine(ray) {
        return new AnkaPromise((resolve, reject) => {
            const [lon, lat, alt, lon2, lat2, alt2] = ray
            let pgl = this._panogl
            let cloud = pgl.getCloudInstance()
            const queryPath = cloud.getURL()
            AnkaFetch(`${queryPath}pc/ray/intercets2/hline/${this.serviceID}/${lon}/${lat}/${alt}/${lon2}/${lat2}/${alt2}`)
                .then(e => e.json())
                .then(e => {
                    if (e.success && e.points.length > 0) {
                        resolve(e.points)
                    } else {
                        resolve(null)
                    }
                })
                .catch(reject)
        })
    }

    addPoint(point) {
        if (Array.isArray(point)) {
            this._points.push(point)
            this.update()
        }
    }

    addPoints(points) {
        if (Array.isArray(points)) {
            for (let i = 0; i < points.length; i++) {
                const point = points[i]
                this._points.push(point)
                this.throwEvent(addPointCreator(this.type, true, this.dh, point))
            }
            this.update()
        } else {
            this.throwEvent(addPointCreator(this.type, false, this.dh, null))
        }
    }

    update() {
        let dh = this.dh
        dh.resetPoints()
        dh.setPoints(this._points.map(([lon, lat, alt]) => ({ lon, lat, alt })))
    }
}

export { CloudPointFromGroundTool }
