import { PCTool } from './PCTool'
import { GeomDataHolder, SPoint } from '../ImportHelper'
import { endEventCreator, addPointCreator } from './EventCreator'
class CloudPointTool extends PCTool {
    constructor (panogl) {
        super(panogl, SPoint, GeomDataHolder.POINT)
        this.type = 'Point'
    }

    addPoint (point) {
        if (Array.isArray(point)) {
            this._points[0] = point
            this.update()
            this.throwEvent(addPointCreator(this.type, true, this.dh, point))
            this.throwEvent(endEventCreator(this.type, true, this.dh))
        } else {
            this.throwEvent(endEventCreator(this.type, false, null))
        }
    }

    onPointsReceived (points) {
        let pt = points[0]
        this.addPoint(pt)
    }

    update () {
        this._points.forEach(([lon, lat, alt]) => {
            let dh = this.dh
            dh.addPoint({ lon, lat, alt })
            dh.setStatus(GeomDataHolder.STATUS.COMPLETED)
        })
    }
}

export { CloudPointTool }
