import { PCTool } from './PCTool'
import { GeomDataHolder, SPolygon } from '../ImportHelper'
import { endEventCreator, addPointCreator } from './EventCreator'

class CloudSquareTool extends PCTool {
    constructor (panogl) {
        super(panogl, SPolygon, GeomDataHolder.POLYGON)
        this.type = 'Polygon'
    }

    endDraw () {
        let dh = this.dh
        let pointNumber = dh.getPointLength()
        let isSuccessful = false
        if (pointNumber < 2) {
            dh.setStatus(GeomDataHolder.STATUS.BAD_COMPLETED)
        } else {
            isSuccessful = true

            let points = this._points
            let point1 = points[0]
            let point2 = points[1]

            let maxAlt = Number.MIN_SAFE_INTEGER
            let minAlt = Number.MAX_SAFE_INTEGER
            for (let i = 0; i < points.length; i++) {
                if (points[i][2] > maxAlt) { maxAlt = points[i][2] }
                if (points[i][2] < minAlt) { minAlt = points[i][2] }
            }

            let p1 = [point1[0], point1[1], maxAlt]
            let p2 = [point2[0], point2[1], maxAlt]
            let p3 = [point2[0], point2[1], minAlt]
            let p4 = [point1[0], point1[1], minAlt]
            let p5 = [point1[0], point1[1], maxAlt]

            this._points = [p1, p2, p3, p4, p5]
            this.update()

            dh.setStatus(GeomDataHolder.STATUS.COMPLETED)
        }

        this.throwEvent(endEventCreator(this.type, isSuccessful, this.dh))
    }

    addPoint (point) {
        if (Array.isArray(point)) {
            this._points.push(point)
            this.update()
            this.throwEvent(addPointCreator(this.type, true, this.dh, point))
            if (this._points.length >= 2) {
                this.endDraw()
            }
        } else {
            this.throwEvent(addPointCreator(this.type, false, this.dh, point))
        }
    }

    onPointsReceived (points) {
        let pt = points[0]
        this.addPoint(pt)
    }

    update () {
        let dh = this.dh
        dh.resetPoints()
        dh.setPoints(this._points.map(([lon, lat, alt]) => ({ lon, lat, alt })))
    }
}

export { CloudSquareTool }
