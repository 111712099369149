import { PCTool } from './PCTool'
import { GeomDataHolder, SLine } from '../ImportHelper'
import { endEventCreator, addPointCreator } from './EventCreator'

class CloudLineTool extends PCTool {
    constructor (panogl) {
        super(panogl, SLine, GeomDataHolder.LINE)
        this.type = 'Line'
    }

    endDraw () {
        let dh = this.dh
        let pointNumber = dh.getPointLength()
        let isSuccessful = false
        if (pointNumber < 2) {
            dh.setStatus(GeomDataHolder.STATUS.BAD_COMPLETED)
        } else {
            isSuccessful = true
            dh.setStatus(GeomDataHolder.STATUS.COMPLETED)
        }
        this.throwEvent(endEventCreator(this.type, isSuccessful, this.dh))
    }

    onPointsReceived (points) {
        let pt = points[0]
        this.addPoint(pt)
    }

    addPoint (point) {
        if (Array.isArray(point)) {
            this._points.push(point)
            this.update()
            this.throwEvent(addPointCreator(this.type, true, this.dh, point))
        } else {
            this.throwEvent(addPointCreator(this.type, false, this.dh, null))
        }
    }

    update () {
        let dh = this.dh
        dh.resetPoints()
        dh.setPoints(this._points.map(([lon, lat, alt]) => ({ lon, lat, alt })))
    }
}

export { CloudLineTool }
