import { Base, AnkaPromise, PanoGL, AnkaFetch, GeomDataHolder, GeometryObserver, CursorManager } from '../ImportHelper'
import { getGeographicRays } from './Calc'
import { endEventCreator, lastPointRemoved, pointAddError } from './EventCreator'

let lastHit = {}
let divData
let context

let canvasWidth = 1024 * 1
let canvasHeight = 512 * 1
let canvas
function createCanvas(img) {
    if (divData === undefined) {
        divData = document.createElement('div')
        divData.style.width = '10px'
        divData.style.height = '10px'
        divData.style.backgroundColor = '#ff0000'
        divData.style.position = 'absolute'
        divData.style.zIndex = 111
    }

    if (context === undefined) {
        canvas = document.createElement('canvas')
        context = canvas.getContext('2d')
        canvas.width = img.width
        canvas.height = img.height
    }

    context.clearRect(0, 0, canvas.width, canvas.height)
    context.drawImage(img, 0, 0, img.width, img.height)
    canvas.style.position = 'absolute'
    canvas.style.top = '0px'
    canvas.style.left = '0px'
    canvasWidth = img.width
    canvasHeight = img.height

    canvas.style.visibility = 'hidden'
}

var RGBToHex = function (r, g, b) {
    var bin = r << 16 | g << 8 | b
    return bin
}

class PCTool extends Base {
    constructor(panogl, drawingClass, geomType) {
        super()
        this._panogl = panogl
        this._depthEnabled = false
        this._points = []
        this.type = 'unknown'
        this._allowClick = true
        this.onClick = this.onClick.bind(this)
        this._onDataChange = this._onDataChange.bind(this)
        let dh = new GeomDataHolder(drawingClass, [], {})
        dh.type = geomType
        dh.setStatus(GeomDataHolder.STATUS.DRAWING)
        GeometryObserver.addGeometries([dh])
        this.dh = dh
        panogl.addEvent(PanoGL.SAFE_CLICK2D, this.onClick)
        panogl.disableGroundNav(true)
        var gm = panogl.getGroundMaster()
        gm.disableNavigation()
        gm.showGroundSymbol(false)
        CursorManager.setCursor('crosshair', 666)



        this.onKeydown = this.onKeydown.bind(this)
        document.addEventListener('keydown', this.onKeydown)

        this._serviceID = 0
        this._currentDepth = {
        }
    }

    set serviceID(v) {
        this._serviceID = v
    }

    get serviceID() {
        return this._serviceID
    }

    disableDepth() {
        this._depthEnabled = false
        let panogl = this._panogl
        panogl.removeEvent(PanoGL.MOUSE_MOVE2D, this.onMouseMove)
        panogl.removeEvent(AnkaPanAPI.PanoGLV2.LOCATION_CHANGE, this._onDataChange)
    }

    onKeydown(e) {
        if (e.keyCode === 27) {
            this.endBadDraw()
        }
    }

    enableDepth() {
        this.disableDepth()
        this._depthEnabled = true
        let panogl = this._panogl
        panogl.addEvent(PanoGL.MOUSE_MOVE2D, this, this.onMouseMove)
        panogl.addEvent(AnkaPanAPI.PanoGLV2.LOCATION_CHANGE, this, this._onDataChange)
        this._downloadDepth()
    }

    _downloadDepth() {
        let currentData = this._panogl.getCurrentPoint()
        let { latlon, parent, img, dirname } = currentData
        if (this._currentDepth.depthPos !== latlon) {
            img = img.replace('.jpeg', '.png')
            let canvasImage = new Image()
            canvasImage.crossOrigin = 'Anonymous'
            canvasImage.onload = () => {
                this._currentDepth.depthPos = latlon
                createCanvas(canvasImage)
            }
            let pgl = this._panogl
            let cloud = pgl.getCloudInstance()
            const queryPath = cloud.getURL()
            canvasImage.src = `${queryPath}depth/${parent}/${dirname}/${img}`
        }
    }

    _onDataChange(evt) {
        this._downloadDepth()
    }

    onMouseMove(e) {
        if (!context) return false

        let direction = e.ray.direction.clone()
        let yaw = -Math.atan2(direction.x, direction.z)
        let yawDegree = yaw * 180 / Math.PI

        yawDegree %= 360

        let pitch = Math.asin(-direction.y)
        let pitchDegree = pitch * 180 / Math.PI

        let indexX = Math.floor(canvasWidth * (yawDegree / 360))
        let indexY = Math.floor(canvasHeight * ((pitchDegree + 90) / 179))

        if (indexX < 0) indexX += canvasWidth

        divData.style.top = indexY + 'px'
        divData.style.left = indexX + 'px'

        var pixel = context.getImageData(indexX, indexY, 1, 1)
        pixel = pixel.data

        let r, g, b
        let isFound = false
        let channel = 0
        for (let i = 0; i < 1; i++) {
            channel = i * 4
            r = pixel[channel]
            g = pixel[channel + 1]
            b = pixel[channel + 2]
            if (r !== 0 || g !== 0 || b !== 0) {
                isFound = true
                break
            }
        }

        if (!e.onGui) {
            if (!isFound) {
                lastHit.valid = false
                CursorManager.setCursor('not-allowed', 666)
            } else {
                lastHit.valid = true
                let scale = 1111
                lastHit.yaw = yawDegree
                lastHit.pitch = pitchDegree
                lastHit.dist = RGBToHex(r, g, b) / scale
                CursorManager.setCursor('crosshair', 666)

                let yaw = lastHit.yaw
                let yawInDeg = yaw
                yawInDeg += 90

                let distance = lastHit.dist
                let y = Math.sin(pitch) * distance
                let neighbour = Math.cos(pitch) * distance

                let x = Math.cos(yawInDeg / 180 * Math.PI) * neighbour
                let z = Math.sin(yawInDeg / 180 * Math.PI) * neighbour

                let panogl = this._panogl
                let position = panogl.groundMaster.posToLoc({ x, y: -y, z })
                lastHit.position = position
            }
        } else {
            CursorManager.setCursor('pointer', 666)
        }
    }

    endBadDraw() {
        let dh = this.dh
        dh.setStatus(GeomDataHolder.STATUS.BAD_COMPLETED)
        GeometryObserver.removeGeometry(dh)
        this.throwEvent(endEventCreator(this.type, false, this.dh))
    }

    destroy() {
        let panogl = this._panogl
        this.disableDepth()
        panogl.removeEvent(PanoGL.SAFE_CLICK2D, this.onClick)
        document.removeEventListener('keydown', this.onKeydown)
        panogl.disableGroundNav(false)
        var gm = panogl.getGroundMaster()
        gm.enableNavigation()
        gm.showGroundSymbol(true)
        CursorManager.setCursor('default', -1, true)
        panogl.setDirty()
    }

    set allowClick(v) {
        this._allowClick = v
        if (!v) {
            CursorManager.setCursor('progress', 666)
        } else {
            CursorManager.setCursor('crosshair', 666)
        }
    }

    get allowClick() {
        return this._allowClick
    }

    addPoint(point) {
        throw new Error('addPoint Needs to be implemented')
    }

    removeLastPoint() {
        if (this._points.length > 0) {
            this._points.pop()
            this.update()
        }
        this.throwEvent(lastPointRemoved(this.type, true, this.dh))
    }

    update() {
        throw new Error('update Needs to be implemented')
    }

    endDraw() {
        this.endBadDraw();
    }

    onClick(e) {

        if (!this._panogl.isUserActive) { // User panoramaya odaklanmadıysa
            return
        }

        if (e.nativeEvent.button === 2) {
            this.endDraw()
            return
        }

        if (e.nativeEvent.button === 1) {
            this.removeLastPoint()
            return
        }

        if (e.onGui) return



        if (this._depthEnabled) {
            if (lastHit.valid) {
                let position = lastHit.position
                console.log(position)

                this.allowClick = true
                this.onPointsReceived(
                    [
                        [position.lon, position.lat, position.alt]
                    ]
                )
            }
        } else {
            const point = this._panogl.getCurrentPoint()

            if (this.allowClick) {
                this.allowClick = false
                let rayPoints = getGeographicRays({ alt: point.altitude, lat: point.lat, lon: point.lon }, e.ray)
                this.queryLine(rayPoints)
                    .then(points => {
                        this.allowClick = true
                        if (Array.isArray(points)) {
                            this.onPointsReceived(points)
                        }
                    })
                    .catch(e => {
                        this.allowClick = true
                        this.throwEvent(pointAddError(this.type, e, false))
                        console.log(e)
                    })
            }
        }
    }

    onPointsReceived() {
        throw new Error('onPointsReceived(points) Needs to be implemented')
    }

    queryLine(ray) {
        return new AnkaPromise((resolve, reject) => {
            const [lon, lat, alt, lon2, lat2, alt2] = ray

            let pgl = this._panogl
            let cloud = pgl.getCloudInstance()
            const queryPath = cloud.getURL()

            AnkaFetch(`${queryPath}pc/ray/intercets2/${this.serviceID}/${lon}/${lat}/${alt}/${lon2}/${lat2}/${alt2}`)
                .then(e => e.json())
                .then(e => {
                    if (e.success && e.points.length > 0) {
                        resolve(e.points)
                    } else {
                        resolve(null)
                    }
                })
                .catch(reject)
        })
    }
}

PCTool.NO_DEPTH = false
export { PCTool }
