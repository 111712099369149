let maxPoolSize = 400
let _poolCount = 0
let _pools = {}
let poolByTimestapms = []

function PatchPooler (params) {
    throw new Error('cannot created instance from a static class')
}

function removeOlds () {
    if (_poolCount > maxPoolSize) {
        let extraPoints = poolByTimestapms.length - maxPoolSize
        let spliced = poolByTimestapms.splice(0, extraPoints)
        spliced.forEach(i => delete _pools[i.id])
        _poolCount -= spliced.length
    }
}

PatchPooler.addByID = function (points, id) {
    let timestamp = Date.now()
    let patch = {
        id,
        timestamp,
        points
    }

    if (_pools[id] === undefined) {
        _poolCount++
    } else {
        // TODO binary search
        let exTS = _pools[id].timestamp
        for (let i = 0; i < poolByTimestapms.length; i++) {
            if (exTS === poolByTimestapms[i].timestamp) {
                poolByTimestapms.splice(i, 1)
                break
            }
        }
    }

    poolByTimestapms.push(patch)
    _pools[id] = patch
    removeOlds()
}

PatchPooler.getByID = function (id) {
    let p = _pools[id]
    if (p !== undefined) {
        return p.points
    }
    return undefined
}

window.PatchPooler = PatchPooler
export { PatchPooler }
