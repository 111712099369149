/* eslint-disable */
import { PatchPooler } from './PatchPooler'
import { CloudPointTool } from './drawing-tools/CloudPointTool'
import { CloudLineTool } from './drawing-tools/CloudLineTool'
import { CloudHLineTool } from './drawing-tools/CloudHLineTool'
import { CloudSquareTool } from './drawing-tools/CloudSquareTool'
import { CloudPolygonTool } from './drawing-tools/CloudPolygonTool'
import { CloudPointFromGroundTool } from './drawing-tools/CloudPointFromGroundTool'
import { PanoGL, Renderer, setDef } from './ImportHelper'
import { PointLoader } from './PointLoader'
// import { CloudProfileTool } from './drawing-tools/CloudProfileTool';
// import { CloudProfileTool2 } from './drawing-tools/CloudProfileTool2';

const DRAW_TYPES = {
    POINT: 0,
    LINE: 1,
    POLYGON: 2,
    HLINE: 3,
    SQUARE: 4,
    GROUND_FROM_TOP: 5,
    // PROFILE: 6,
    SLOPE: 7,
    // PROFILE2: 8,
}

function PointCloudPlugin(params) {
    AnkaPanAPI.PanoPlugin.apply(this, [])
    let { enablePoints, url, serviceID } = params
    this._serviceID = setDef(serviceID, 0)
    this._enablePoints = enablePoints
    this._url = url + (/(\/|\\)$/.test(url) ? '' : '/')
    this.cloneable = false
    this._onToolStatusComplete = this._onToolStatusComplete.bind(this)
    this._noDepth = true
    Object.defineProperties(this, {
        enablePoints: {
            set: function (v) {
                this._enablePoints = v
                this._pointLoader.setDisable(!this._enablePoints)
            },
            get: function () {
                return this._enablePoints
            }
        }
    })

}

PointCloudPlugin._counter = 0
PointCloudPlugin.DRAW_TYPES = DRAW_TYPES
PointCloudPlugin.prototype = Object.assign(Object.create(AnkaPanAPI.PanoPlugin.prototype), {
    constructor: PointCloudPlugin,
    _pointScene: new THREE.Scene(),
    _cursorScene: new THREE.Scene(),
    _id: 'pc_' + (PointCloudPlugin._counter++),
    _currentGID: undefined,
    _visibility: true,
    allowDownload: true,
    prepare: function (e) {
        let panogl = this.baseObject
        this._pointLoader = new PointLoader(this._url, this._serviceID, panogl)
        this._pointLoader.setDisable(!this._enablePoints)
        Renderer.getInstance().addPanoGLInstance(this)
        panogl.getCloudInstance = () => { return this }
        panogl.addEvent(PanoGL.RENDER_AFTER_GROUND, this, this.onRenderAfterGround)
    },

    getURL() {
        return this._url
    },

    setLoadData(b) {
        if (this._loadPoints !== b) {
            this._loadPoints = b
        }
    },

    startDrawing(type, serviceID) {
        let curentTool
        serviceID = setDef(serviceID, this._serviceID)

        this.stopDraw()

        if (type === DRAW_TYPES.POINT) {
            curentTool = new CloudPointTool(this.baseObject)
        } else if (type === DRAW_TYPES.LINE) {
            curentTool = new CloudLineTool(this.baseObject)
        } else if (type === DRAW_TYPES.POLYGON) {
            curentTool = new CloudPolygonTool(this.baseObject)
        } else if (type === DRAW_TYPES.HLINE) {
            return alert("This tool cannot be used!")
            // curentTool = new CloudHLineTool(this.baseObject)
        } else if (type === DRAW_TYPES.SLOPE) {
            curentTool = new CloudHLineTool(this.baseObject)
        } else if (type === DRAW_TYPES.SQUARE) {
            curentTool = new CloudSquareTool(this.baseObject)
        } else if (type === DRAW_TYPES.GROUND_FROM_TOP) {
            curentTool = new CloudPointFromGroundTool(this.baseObject)
        } else if (type === DRAW_TYPES.PROFILE) {
            console.log("DRAW_TYPES.PROFILE deprecated");
            // curentTool = new CloudProfileTool(this.baseObject)
        } else if (type === DRAW_TYPES.PROFILE2) {
            console.log("DRAW_TYPES.PROFILE2 deprecated");
            // this.activateProfiles()
            // curentTool = new CloudProfileTool2(this.baseObject)
        }

        if (curentTool) {

            curentTool.serviceID = serviceID
            if (this._noDepth) {
                curentTool.disableDepth()
            } else {
                curentTool.enableDepth()
            }

            curentTool.addEvent('onStatusChange', this._onToolStatusComplete)
            this._currentTool = curentTool
        } else {
            console.warn('invalid tool');
        }

    },

    addPointToDraw(lon, lat, alt) {
        if (this._currentTool) {
            this._currentTool.addPoint([lon, lat, alt])
        }
    },

    enableDepth() {
        this._noDepth = false
        if (this._currentTool) {
            this._currentTool.disableDepth()
        }
    },

    disableDepth() {
        this._noDepth = true
        if (this._currentTool) {
            this._currentTool.enableDepth()
        }
    },

    stopDraw() {

        if (this._currentTool) {
            this._currentTool.endBadDraw()
            this.destroyDraw()
            this._currentTool = null
        }
    },

    destroyDraw() {
        if (this._currentTool) {
            this._currentTool.destroy()
        }
        this._currentTool = null
    },

    _onToolStatusComplete(event) {
        if (event.status === 'FINISHED') {
            this.destroyDraw()
        }
        this.throwEvent(event)
    },

    setVisibility: function (b) {
        this._visibility = b
        // if (b) {
        //     this.refresh()
        // }
        this.setDirty()
    },

    onRenderAfterGround: function () {
        this._pointLoader.update()
    },

    _setPointParams: function (points) {
        this._pointMeta = points.map(i => Object.assign({}, i))
        this._nextID = 0
        for (let i = 0; i < 10; i++) {
            if (this._nextID < this._pointMeta.length - 1) {
                this._nextID = i
                this.addPointData(this._pointMeta[i], i)
            } else {
                break
            }
        }
    },

    addPointData: function (point, id) {
        if (point) {
            let id = point.id
            let url = this._url + `/pc/fetch/${id}`
            let patches = PatchPooler.getByID(id)
            if (patches !== undefined) {
                this._processPoints(patches)
                return
            }

            let currentGID = this._currentGID
            AnkaPanAPI.AnkaFetch(url)
                .then(e => {
                    if (currentGID !== this._currentGID) return undefined
                    let json = e.json()
                    return json
                })
                .then(result => {
                    if (currentGID !== this._currentGID) return

                    if (result && result.success) {
                        var points = result.points
                        PatchPooler.addByID(points, id)
                        this._processPoints(points)
                    } else {
                        console.log('Not succesfull')
                    }
                })
        }
    },

    clearData() {
        const scene = this._pointScene
        const children = scene.children
        while (children.length > 0) {
            let child = children[0]
            child.geometry.dispose()
            child.material.dispose()
            scene.remove(child)
        }
    }
})

PointCloudPlugin.STATUS_CHANGE = 'onStatusChange'

export { PointCloudPlugin }
/* eslint-enable */
