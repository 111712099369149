
/**
 *
 * @param {string} drawType
 * @param {boolean} isSuccessful
 * @param {*} feature
 */
function endEventCreator (drawType, isSuccessful, feature) {
    return {
        drawType,
        type: 'onStatusChange',
        status: 'FINISHED',
        isSuccessful,
        feature }
}

/**
 *
 * @param {string} drawType
 * @param {Boolean} isSuccessful
 */
function lastPointRemoved (drawType, isSuccessful, feature) {
    return {
        drawType,
        type: 'onStatusChange',
        status: 'LAST_POINT_REMOVED',
        isSuccessful,
        feature
    }
}

/**
 *
 * @param {string} drawType
 * @param {Error} error
 * @param {Boolean} isSuccessful
 * @return {Object}
 */
function pointAddError (drawType, error, isSuccessful) {
    return {
        drawType,
        type: 'onStatusChange',
        error,
        status: 'POINT_ADDED_ERROR',
        isSuccessful }
}

/**
 *
 * @param {string} drawType
 * @param {boolean} isSuccessful
 * @param {*} feature
 * @param {Array} point
 */
function addPointCreator (drawType, isSuccessful, feature, point) {
    let event = {
        drawType,
        type: 'onStatusChange',
        status: 'POINT_ADDED',
        isSuccessful,
        feature }

    if (point !== null) {
        event.lon = point[0]
        event.lat = point[1]
        event.alt = point[2]
    }

    return event
}

export {pointAddError, addPointCreator, endEventCreator, lastPointRemoved}
