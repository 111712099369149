
function getGeographicRays (centerPos, ray) {
    let direction = ray.direction
    let rotaYaw = (Math.PI * 1) - Math.atan2(direction.x, direction.z)

    let distance = 90
    let pitch = Math.asin(-direction.y)

    let lon = centerPos.lon
    let lat = centerPos.lat
    let alt = centerPos.alt

    let destination = AnkaPanAPI.Utils.destinationPoint(lat, lon, distance, rotaYaw * 180 / Math.PI)
    let alt2 = alt - (Math.tan(pitch) * distance)

    return [lon, lat, alt,
        destination.lon, destination.lat, alt2]
}

export { getGeographicRays }
